import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "what-cookies-and-why-is-smilee-chat-using"
    }}>{`What cookies and why is Smilee Chat using`}</h2>
    <p>{`All of the cookies set by Smilee Chat are techincal session cookies used to provide better user experience for our customers.`}</p>
    <p>{`These cookies cannot be used for tracking/identification/marketing purposes by Smilee.`}</p>
    <h3 {...{
      "id": "kcobsid"
    }}>{`kcob.sid`}</h3>
    <p><inlineCode parentName="p">{`kcob.sid`}</inlineCode>{` cookie is an `}<a parentName="p" {...{
        "href": "https://expressjs.com/"
      }}>{`Express`}</a>{` session cookie. This is a technical cookie used to keep the
users session alive and connected in the backend.`}</p>
    <h3 {...{
      "id": "cobuser"
    }}>{`cobUser`}</h3>
    <p><inlineCode parentName="p">{`cobUser`}</inlineCode>{` cookie is an session cookie used by the Smilee Chat backend to handle the user session in case of reconnection.`}</p>
    <h3 {...{
      "id": "gaesa"
    }}>{`GAESA`}</h3>
    <p><inlineCode parentName="p">{`GAESA`}</inlineCode>{` is a session cookied used by loadbalancer to send traffic to same backend. This is a technical cookie that enhances the userexperience.`}</p>
    <h2 {...{
      "id": "localstorage-and-sessionstorage"
    }}>{`localStorage and sessionStorage`}</h2>
    <p>{`localStorage is used to carry the state of the chat between page changes.`}</p>
    <p><inlineCode parentName="p">{`smilee-sessionId`}</inlineCode>{` is a key used to store the session id of the chat.`}</p>
    <p><inlineCode parentName="p">{`smilee-enduser-client-instance-id`}</inlineCode>{` is a key used to store the client instance id of the chat to idenfity if the chat is open in multiple browsers windows. This is needed to make sure we deliver the message to all of the chat instances.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      